@import "embed.min.css";
// Import partials from `sass_dir` (defaults to `_sass`)

.btn-primary{
    background-image: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%) !important;
    background-color: #e61e50;
    border: 2px solid #e51f50;
    
    &:hover{
        background-color: #e61e50;
        border: 2px solid #e61e50;
    }
}

// .bg-dark {
//     background: linear-gradient(to right,#86a3c2 0,#99b8b6 100%);
// }
.beforefooter{
    background: linear-gradient(to right,#86a3c2 0,#99b8b6 100%);
}

.intro .header {
    text-shadow: 1.5px 1.5px 1.5px #000000;
}
.wrapintro .lead {
    text-shadow: 1.5px 1.5px 1.5px #000000;
}
a.aff-link.btn {
    color: #fff;
    font-size: 1.3em;
    font-weight: bold;
    text-shadow: 0.5px 0.5px 0.5px #000000;
}
.product-card .card-body {
    background: #fff;
    border-radius: 16px;
}

.hero-image > .flex-container{
    z-index: 1000;
}

.brand-logo img{
    max-width: 200px;
}
.article-post a:not(.btn) {
    color: #3d80cc;
}

.ui.table{
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    vertical-align: middle;
    color: rgba(0,0,0,.87);
    border-collapse: separate;
    border-spacing: 0;
}
.ui.table>tbody>tr:first-child{
    font-weight: bold;
}
.ui.table>tbody>tr>td, .ui.table>tr>td {
    padding: 0.78571429em 0.78571429em;
    text-align: inherit;
    border: 1px solid rgba(34,36,38,.1);

}
body .ui.cards>.card{
	box-shadow: none;
}
.footer.bg-dark .ui.link.list a,h4{
    color: white;
}

.best-sellers-gallery-component{
    .ui .card .content{
        padding: 0px;
    }
    .btn-primary{
        display: none;
    }
    .product-name{
        padding: 7px;
        color: #6435c9;
    }
}